.about-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.about-content {
  height: 60%;
  text-align: left;
  padding: 30px;
}

.about-text {
  font-size: 15px;
  margin-bottom: 10px;
}

.about-text > span {
  margin-bottom: 10px;
}

.flex-large {
  flex: 3;
}

.flex-small {
  flex: 1;
}

.project-card {
  margin: 10px;
}
