.contact-page {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: #2d2d2d;
}

.contact-form-container {
  padding: 40px;
  background-color: white;
  width: 50%;
  margin: 40px;
  border-radius: 5px;
  height: 90%;
}

.contact-form-container h1 {
  margin-bottom: 30px;
  vertical-align: center;
}

.contact-form-container img {
  height: 50px;
  margin: 10px;
}
