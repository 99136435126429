.home-container {
  flex: auto;
}

.home-img {
  height: 100%;
  display: flex;
  justify-content: right;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 200px 40px 20px 40px;
  height: 100%;
  width: 40%;
}

.intro-text {
  display: flex;
  flex-direction: column;
  color: white;
}

.switch-header {
  font-size: 100px;
}

.sub-header {
  padding-bottom: 15px;
  border-bottom: #f60703 solid;
  margin-left: 5px;
  font-size: 30px;
}

h1 > b {
  font-size: 40px;
}

.intro-content {
  font-size: 20px;
}

.contact-redirect {
  margin-top: 20px;
}

.contact-button {
  background-color: #f60703;
  border-color: #f60703;
}
