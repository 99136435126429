@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&family=Roboto+Slab&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-icon {
  height: 48px;
  flex: 1 1;
}

.home-text {
  margin-left: 5px;
  font-weight: 500;
  font-size: 20px;
  vertical-align: middle;
  font-family: "Roboto Slab", sans-serif;
  display: flex;
  flex-direction: column;
  flex: 2 1;
}

.nav-logo {
  border-left: 5px solid #f60703;
  padding: 4px;
}

.main-nav {
  justify-content: space-between;
}

.nav-menu .nav-item {
  margin-top: 20px;
  border-bottom: 5px solid transparent;
  width: 110px;
}

.nav-menu .nav-item a {
  text-decoration: none;
  color: black;
}

.active {
  color: #f60703 !important;
  pointer-events: none;
  font-weight: bold;
}

.nav-menu .nav-item a:hover {
  cursor: pointer;
  color: #f60703;
  font-weight: bold;
}

.nav-menu .nav-item:hover {
  border-bottom: 5px solid #f60703;
}

.nav-menu {
  display: flex;
}

.home-container {
  flex: auto;
}

.home-img {
  height: 100%;
  display: flex;
  justify-content: right;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 200px 40px 20px 40px;
  height: 100%;
  width: 40%;
}

.intro-text {
  display: flex;
  flex-direction: column;
  color: white;
}

.switch-header {
  font-size: 100px;
}

.sub-header {
  padding-bottom: 15px;
  border-bottom: #f60703 solid;
  margin-left: 5px;
  font-size: 30px;
}

h1 > b {
  font-size: 40px;
}

.intro-content {
  font-size: 20px;
}

.contact-redirect {
  margin-top: 20px;
}

.contact-button {
  background-color: #f60703;
  border-color: #f60703;
}

.main-footer {
  font-size: 12px;
  height: 15px;
}

.react-multi-carousel-list {
  height: 300px;
  margin-bottom: 10px;
}

.react-multi-carousel-track {
  height: 100%;
}

.image-item {
  width: auto !important;
  height: 100%;
  margin: 0 5px 0 5px;
}

.section {
  text-align: left;
  padding-left: 10px;
}

.section-name {
  border-left: 5px solid #f60703;
}

.gallery-header {
  text-align: left;
  padding: 10px;
  color: white;
  background-color: #2d2d2d;
}

.about-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.about-content {
  height: 60%;
  text-align: left;
  padding: 30px;
}

.about-text {
  font-size: 15px;
  margin-bottom: 10px;
}

.about-text > span {
  margin-bottom: 10px;
}

.flex-large {
  flex: 3 1;
}

.flex-small {
  flex: 1 1;
}

.project-card {
  margin: 10px;
}

.col-form-label {
  text-align: right;
  font-weight: bold;
}

.contact-form .form-control {
  border: none;
  background-color: #f5f5f5;
  color: #888888;
}

.contact-form .btn {
  background-color: #f60703;
  border: none;
  color: white;
  padding: 10px 0;
  margin-top: 20px;
  width: 200px;
}

.contact-form .btn:hover {
  background-color: #b90502;
}

.required-field {
  color: red;
}

.message-box {
  height: 150px;
  width: 100% !important;
}

.field-box {
  width: 80% !important;
}

.contact-page {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: #2d2d2d;
}

.contact-form-container {
  padding: 40px;
  background-color: white;
  width: 50%;
  margin: 40px;
  border-radius: 5px;
  height: 90%;
}

.contact-form-container h1 {
  margin-bottom: 30px;
  vertical-align: center;
}

.contact-form-container img {
  height: 50px;
  margin: 10px;
}

